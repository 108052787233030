import styles from './table-card.module.css';
import CardLayout from '../card-layout/card-layout';
import MetricCardHeader from '../metric-card-header/metric-card-header';
import { SortableTable } from '@sqior/react/uibase';
import { TableData } from '@sqior/viewmodels/analytics-dashboard';

export interface TableCardProps {
  title: string;
  subTitle?: string;
  hideSettings?: boolean;
  data: TableData;
}

export function TableCard({ title, subTitle, hideSettings, data }: TableCardProps) {
  return (
    <CardLayout>
      <MetricCardHeader title={title} subTitle={subTitle} hideSettings={hideSettings} />
      <SortableTable
        data={data.rows}
        columns={data.columns.map((col, index) => {
          return { ...col, numeric: index > 0 };
        })}
      />
    </CardLayout>
  );
}

export default TableCard;
