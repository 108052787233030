import { FC } from 'react';
import { FactoryProps } from '@sqior/react/factory';
import { ORWorkflowOverviewDashboardQuota } from '@sqior/viewmodels/orworkflow';
import { useUIGlobalState } from '@sqior/react/state';

// Utility function to convert basic colors to rgba with opacity
const getColorWithOpacity = (color: string, opacity: number) => {
  const colors: { [key: string]: string } = {
    red: 'rgba(255, 0, 0,',
    yellow: 'rgba(255, 255, 0,',
    blue: 'rgba(0, 0, 255,',
    green: 'rgba(0, 128, 0,',
    pink: 'rgba(255, 192, 203,',
    orange: 'rgba(255, 165, 0,',
    purple: 'rgba(128, 0, 128,',
    violet: 'rgba(238, 130, 238,',
    cyan: 'rgba(0, 255, 255,',
    white: 'rgba(255, 255, 255,',
    gray: 'rgba(128, 128, 128,',
    salmon: 'rgba(250, 128, 114,',
    brawn: 'rgba(165, 42, 42,',
    lightGreen: 'rgba(144, 238, 144,',
    teal: 'rgba(0, 128, 128,',
  };

  return `${colors[color]} ${opacity})`;
};
type BGMarkerViewProps = FactoryProps<ORWorkflowOverviewDashboardQuota>;

const OUTER_SPACE = 4;
const BackgroundMarker: FC<BGMarkerViewProps> = ({ data }) => {
  const {
    UIGlobalState: { opShowBackgroundMarker },
  } = useUIGlobalState();

  if (!opShowBackgroundMarker) return null;

  const color = data.specialty?.specialtyColor || getColorWithOpacity('yellow', 0.15);

  const title = data.title ?? data.description;

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: -OUTER_SPACE,
        right: -OUTER_SPACE,
        background: `linear-gradient(0deg, ${getColorWithOpacity(
          color,
          0.15
        )} 0%, ${getColorWithOpacity(color, 0.05)} 50%, ${getColorWithOpacity(color, 0.15)} 100%)`,
        padding: 5,
      }}
    >
      {title && (
        <div
          style={{
            fontSize: 12,
            color: color || 'white',
          }}
        >
          {title}
        </div>
      )}
    </div>
  );
};

export default BackgroundMarker;
