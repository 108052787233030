import { EntityHeader } from '@sqior/js/entity';
import { MetricCardStats, PieCardData, TimeRange } from './types';
import { ListData, ListSelectionData } from '@sqior/viewmodels/input';

export const OccupancyAnalyticsDashboard = 'OccupancyAnalyticsDashboard';

export type OccupancyAnalyticsDashboardVM = EntityHeader & OccupancyAnalyticsData;

export type OccupancyAnalyticsPerformanceVM = {
  [K in OccupancyMetricCardTypes]: MetricCardStats;
} & {
  [K in OccupancyPieCardTypes]: PieCardData;
};


export type OccupancyAnalyticsData = {
  wards: ListSelectionData<ListData[]>;
  timeRanges: ListSelectionData<TimeRange[]>;
  stats: OccupancyAnalyticsPerformanceVM;
};

export type WardStatDataBase = Record<OccupancyMetricCardTypes | OccupancyPieCardTypes, MetricCardStats | PieCardData>;

export enum OccupancyMetricCardTypes {
  Spi = 'spi',
  OccupancyRate = 'occupancyRate',
  StayDuration = 'stayDuration',
  SPIBelowTwentyFive = 'spiBelowTwentyFive',
}

export enum OccupancyPieCardTypes {
  PatientsRequestSource = 'patientsRequestSource',
}