import styles from './patient-qrcode-page.module.css';
import { QRCodeContent } from '@sqior/viewmodels/patient';
import { FactoryProps } from '@sqior/react/factory';
import Barcode from 'react-barcode';

export type PatientQRCodePageProps = FactoryProps<QRCodeContent>;

export function PatientQRCodePage({ data: { code } }: PatientQRCodePageProps) {
  return (
    <div className={styles['container']}>
      <div className={styles['wrapper']}>
        <Barcode format="CODE128" value={code} width={3} />
      </div>
    </div>
  );
}

export default PatientQRCodePage;
