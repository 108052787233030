import { Icon } from '@iconify/react';
import { Icons } from '@sqior/viewmodels/visual';
import { cloneElement, CSSProperties, ReactElement } from 'react';
import { ReactComponent as Cancel } from './svg-icons/cancel.svg';
import { ReactComponent as ChangeDescription } from './svg-icons/change-description.svg';
import { ReactComponent as ChangeLocation } from './svg-icons/change-location.svg';
import { ReactComponent as Inserted } from './svg-icons/inserted.svg';
import { ReactComponent as Reordered } from './svg-icons/reordered.svg';
import { ReactComponent as EventBusy } from './svg-icons/event-busy.svg';
import { ReactComponent as Lock } from './svg-icons/lock.svg';
import { ReactComponent as Report } from './svg-icons/report.svg';
import { ReactComponent as EventAvailable } from './svg-icons/event_available.svg';
import { ReactComponent as EventUpcoming } from './svg-icons/event_upcoming.svg';
import { ReactComponent as PatientPositioning } from './svg-icons/patient_positioning.svg';
import { ReactComponent as Team } from './svg-icons/team.svg';
import { ReactComponent as Checklist } from './svg-icons/checklist.svg';
import { ReactComponent as Surgical } from './svg-icons/surgical.svg';
import { ReactComponent as GpsLocation } from './svg-icons/location.svg';
import { ReactComponent as Spi } from './svg-icons/spi.svg';
import { ReactComponent as Barcode } from './svg-icons/barcode.svg';
import { ReactComponent as Description } from './svg-icons/description.svg';
import { ReactComponent as Anesthesia } from './svg-icons/anesthesia.svg';
import { ReactComponent as Insurance } from './svg-icons/insurance.svg';
import { ReactComponent as Male } from './svg-icons/male.svg';
import { ReactComponent as Female } from './svg-icons/female.svg';
import { ReactComponent as Unisex } from './svg-icons/unisex.svg';
import { ReactComponent as Work } from './svg-icons/work.svg';
import { ReactComponent as Case } from './svg-icons/case.svg';
import { ReactComponent as PremedicationChecked } from './svg-icons/premedication_checked.svg';
import { ReactComponent as PremedicationMissing } from './svg-icons/premedication_missing.svg';
import { ReactComponent as PremedicationNotApproved } from './svg-icons/premedication_notApproved.svg';
import { ReactComponent as Download } from './svg-icons/download.svg';
import { ReactComponent as Visibility } from './svg-icons/visibility.svg';
import { ReactComponent as VisibilityOff } from './svg-icons/visibility_off.svg';
import { ReactComponent as ForwardArrow } from './svg-icons/arrow-forward.svg';
import { ReactComponent as BackArrow } from './svg-icons/arrow-back.svg';

const NO_ICON = <Icon icon="octicon:question-24" fontSize={30} />;
const DEFAULT_SIZE = 24;

export const stringToJSXMap = {
  [Icons.Bed]: NO_ICON,
  [Icons.Calender]: NO_ICON,
  [Icons.Chat]: NO_ICON,
  [Icons.Check]: NO_ICON,
  [Icons.Clock]: NO_ICON,
  [Icons.Escalation]: NO_ICON,
  [Icons.Transport]: NO_ICON,
  [Icons.Oneway]: NO_ICON,
  [Icons.Cancel]: <Cancel />,
  [Icons.ChangeDescription]: <ChangeDescription />,
  [Icons.ChangeLocation]: <ChangeLocation />,
  [Icons.Inserted]: <Inserted />,
  [Icons.Reordered]: <Reordered />,
  [Icons.EventBusy]: <EventBusy />,
  [Icons.Lock]: <Lock />,
  [Icons.Report]: <Report />,
  [Icons.EventAvailable]: <EventAvailable />,
  [Icons.EventUpcoming]: <EventUpcoming />,
  [Icons.PatientPositioning]: <PatientPositioning />,
  [Icons.Team]: <Team />,
  [Icons.CheckList]: <Checklist />,
  [Icons.Barcode]: <Barcode />,
  [Icons.Spi]: <Spi />,
  [Icons.ORLocation]: <Surgical />,
  [Icons.Location]: <GpsLocation />,
  [Icons.Description]: <Description style={{ width: DEFAULT_SIZE, height: DEFAULT_SIZE }} />,
  [Icons.Anesthesia]: <Anesthesia />,
  [Icons.Insurance]: <Insurance />,
  [Icons.Male]: <Male />,
  [Icons.Female]: <Female />,
  [Icons.Unisex]: <Unisex />,
  [Icons.Work]: <Work />,
  [Icons.Case]: <Case />,
  [Icons.PremedicationChecked]: <PremedicationChecked />,
  [Icons.PremedicationMissing]: <PremedicationMissing />,
  [Icons.PremedicationNotApproved]: <PremedicationNotApproved />,
  [Icons.Download]: <Download />,
  [Icons.Visibility]: <Visibility />,
  [Icons.VisibilityOff]: <VisibilityOff />,
  [Icons.BackArrow]: <BackArrow />,
  [Icons.ForwardArrow]: <ForwardArrow />,
} as const;

type StringToJSXMap = typeof stringToJSXMap;
export type StringToJSXMapKeys = keyof StringToJSXMap;

export interface SIconProps extends CSSProperties {
  icon?: Icons | StringToJSXMapKeys;
  size?: number;
  color?: string;
  alternative?: ReactElement;
}

export function SIcon({ icon, size, color = 'white', alternative, ...rest }: SIconProps) {
  if (!icon || !stringToJSXMap[icon]) return alternative ? alternative : NO_ICON;

  return cloneElement(stringToJSXMap[icon], {
    width: size ? size : '100%',
    height: size ? size : '100%',
    color: color,
    fill: color,
    ...rest,
  });
}

export default SIcon;
