import { EntityHeader } from '@sqior/js/entity';
import { OperationSpec, OperationType } from '@sqior/js/operation';
import { joinPath } from '@sqior/js/url';
import { AnalyticsDashboardStatePath } from './paths';
import {
  WorkflowAnalyticsDashboardOpSubPaths,
  WorkflowAnalyticsDashboardStateOperationData,
} from './workflow-analytics-dashboard';
import { BarCardData, MetricCardStats, PieCardData, TableData } from './types';

export const ORWorkflowAnalyticsDashboard = 'ORWorkflowAnalyticsDashboard';
export const ORWorkflowAnalyticsDashboardOpPath = 'ORWorkflowAnalyticsDashboard';

export type ORWorkflowAnalyticsDashboardVM = EntityHeader & {
  // new stuff to add
};

export function ORWorkflowAnalyticsDashboardOperation(
  data: WorkflowAnalyticsDashboardStateOperationData
): OperationSpec<WorkflowAnalyticsDashboardStateOperationData> {
  return { type: OperationType.Add, path: AnalyticsDashboardStatePath, data };
}

export function ORWorkflowAnalyticsDashboardSelectEdge(id: string[]): OperationSpec<string[]> {
  return {
    type: OperationType.Add,
    path: joinPath(AnalyticsDashboardStatePath, WorkflowAnalyticsDashboardOpSubPaths.SelectEdge),
    data: id,
  };
}

export function ORWorkflowAnalyticsDashboardSelectNode(id: string[]): OperationSpec<string[]> {
  return {
    type: OperationType.Add,
    path: joinPath(AnalyticsDashboardStatePath, WorkflowAnalyticsDashboardOpSubPaths.SelectNode),
    data: id,
  };
}

export function ORWorkflowAnalyticsDashboardTimeRange(timeRangeId: string): OperationSpec<string> {
  return {
    type: OperationType.Add,
    path: joinPath(
      AnalyticsDashboardStatePath,
      WorkflowAnalyticsDashboardOpSubPaths.SelectTimeRange
    ),
    data: timeRangeId,
  };
}

export function ORWorkflowAnalyticsDashboardSelectSpecialty(id: string): OperationSpec<string> {
  return {
    type: OperationType.Add,
    path: joinPath(
      AnalyticsDashboardStatePath,
      WorkflowAnalyticsDashboardOpSubPaths.SelectSpecialty
    ),
    data: id,
  };
}

export function ORWorkflowAnalyticsDashboardSelectFilter(id: string): OperationSpec<string> {
  return {
    type: OperationType.Add,
    path: joinPath(AnalyticsDashboardStatePath, WorkflowAnalyticsDashboardOpSubPaths.SelectFilter),
    data: id,
  };
}

/** Performance state */

export const ORWorkflowAnalyticsPerformanceSubPath = 'perf';

export type ORWorkflowAnalyticsPerformanceVM = {
  total: MetricCardStats;
  incidentCategories: BarCardData;
  prepIncidents: PieCardData;
  anesthesiaIncidents: PieCardData;
  surgeryIncidents: PieCardData;
  incisionSuture: MetricCardStats;
  incisionSutureTable: TableData;
};
